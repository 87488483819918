import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routerOptions: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@patient/features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'form/:code',
    loadChildren: () =>
      import('@patient/features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'confirmation',
    loadChildren: () =>
      import('@patient/features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'clinic/:clinicGroupId/home/:homeId',
    loadChildren: () =>
      import('@patient/features/assignments/assignments.module').then(
        (m) => m.AssignmentsModule,
      ),
  },
  {
    path: 'clinic/:clinicGroupId',
    loadChildren: () =>
      import('@patient/features/assignments/assignments.module').then(
        (m) => m.AssignmentsModule,
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
