import { Injectable } from '@angular/core';

import { LogProperties } from '../interfaces/log-properties';
import { ILoggingService } from './i-logging.service';

@Injectable({
  providedIn: 'root',
})
export class MockLoggingService extends ILoggingService {
  override logPageView(name?: string, url?: string) {
    console.log(name, url);
  }

  override logEvent(name: string, properties?: LogProperties) {
    console.log(name, properties);
  }

  override logMetric(
    name: string,
    average: number,
    properties?: LogProperties,
  ) {
    console.log(name, average, properties);
  }

  override logException(exception: Error, severityLevel?: number) {
    console.log(exception, severityLevel);
  }

  override logTrace(message: string, properties?: LogProperties) {
    console.log(message, properties);
  }
}
