import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { FORM_ERRORS } from '../messages/form-errors';

@Pipe({
  name: 'formatError',
  standalone: true,
})
export class FormatErrorPipe implements PipeTransform {
  constructor(@Inject(FORM_ERRORS) private errors: ValidationErrors) {}
  transform(errors: ValidationErrors | null | undefined): string | null {
    if (!errors) {
      return null;
    }
    const firstKey = Object.keys(errors)[0];
    const getError = this.errors[firstKey];
    return getError(errors[firstKey]);
  }
}
