import { Pipe, PipeTransform } from '@angular/core';

import { DateFormatOption, DateService } from '../services';

@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  constructor(private readonly dateService: DateService) {}
  transform(
    dateOrDateString: string | Date | undefined | null,
    dateFormatOption?: DateFormatOption,
  ): string {
    return this.dateService.formatDate(dateOrDateString, dateFormatOption);
  }
}
