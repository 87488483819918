import { InjectionToken } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export const defaultErrors: ValidationErrors = {
  required: () => `This field is required`,
  minLength: ({
    requiredLength,
    actualLength,
  }: {
    requiredLength: number;
    actualLength: number;
  }) =>
    `Minimum characters of ${requiredLength} expected (currently ${actualLength}).`,
  maxlength: ({
    requiredLength,
    actualLength,
  }: {
    requiredLength: number;
    actualLength: number;
  }) =>
    `Maximum characters of ${requiredLength} reached (currently ${actualLength}).`,
  email: () => 'Enter a valid email address.',
  pattern: () => 'Invalid characters used.',
  dateOfBirthBounds: () => 'Date of birth must be between 1900 and today.',
  emailsMustMatch: () => 'Emails must match.',
  minSelectedCheckboxes: () => 'Select at least one option.',
  noClinicsAssigned: () => 'No clinics assigned.',
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
