import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NcIconService } from '@neurocare/ui';
import {
  ILoggingService,
  INSTRUMENTATION_KEY,
  LoggingService,
  MockLoggingService,
} from '@neurocare/utils';
import { environment } from '@patient_environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: INSTRUMENTATION_KEY,
      useValue: environment.appInsights.instrumentationKey,
    },
    {
      provide: ILoggingService,
      useFactory: (instrumentationKey: string) => {
        environment.useAppInsights
          ? new LoggingService(instrumentationKey)
          : new MockLoggingService();
      },
      deps: [INSTRUMENTATION_KEY],
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    NcIconService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
