import { Injectable } from '@angular/core';

import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(readonly loggingService: LoggingService) {}

  handleError(error: Error): void {
    this.loggingService.logException(error);
  }
}
