import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateOfBirthBounds: ValidatorFn = (
  group: AbstractControl,
): ValidationErrors | null => {
  const dateOfBirth = group.get('dateOfBirth');
  let yearOfBirth = 0;
  const oldestYear = 1900;
  const currentYear = new Date().getFullYear();

  if (!dateOfBirth) {
    return { dateOutOfBounds: true };
  }

  if (dateOfBirth.value) {
    const fullDate = new Date(dateOfBirth.value as string);
    yearOfBirth = fullDate.getFullYear();
  }

  if (yearOfBirth <= currentYear && yearOfBirth >= oldestYear) {
    return null;
  } else {
    dateOfBirth.setErrors({ dateOfBirthBounds: true });
    return { dateOfBirthBounds: true };
  }
};
