import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { NcIcon } from './icon.types';

@Injectable({
  providedIn: 'root',
})
export class NcIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  public registerIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path),
    );
  }

  public registerAllIcons(iconsPath: string): void {
    Object.values(NcIcon).forEach((icon) => {
      this.registerIcon(icon, `${iconsPath}/${icon}.svg`);
    });
  }
}
