import { Component } from '@angular/core';
import { NcIconService } from '@neurocare/ui';
import { ILoggingService } from '@neurocare/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'patient';
  constructor(
    readonly loggingService: ILoggingService,
    readonly iconService: NcIconService,
  ) {
    iconService.registerAllIcons('assets/icons');
  }
}
