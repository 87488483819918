import { Pipe, PipeTransform } from '@angular/core';

import { DateService } from '../services';

@Pipe({
  name: 'formatDateRange',
  standalone: true,
})
export class FormatDateRangePipe implements PipeTransform {
  constructor(private readonly dateService: DateService) {}
  transform(
    start: string | null | undefined,
    end: string | null | undefined,
  ): string {
    if (!start && !end) {
      return '';
    }
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return `${this.dateService.formatDate(startDate)} - ${this.dateService.formatDate(endDate)}`;
    } else if (start) {
      const startDate = new Date(start);
      return `${this.dateService.formatDate(startDate)} - Present`;
    }
    return '';
  }
}
