import { Injectable } from '@angular/core';

import { LogProperties } from '../interfaces/log-properties';

@Injectable({
  providedIn: 'root',
})
export abstract class ILoggingService {
  abstract logPageView(name?: string, url?: string): void;

  abstract logEvent(name: string, properties?: LogProperties): void;

  abstract logMetric(
    name: string,
    average: number,
    properties?: LogProperties,
  ): void;

  abstract logException(exception: Error, severityLevel?: number): void;

  abstract logTrace(message: string, properties?: LogProperties): void;
}
