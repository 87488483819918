export enum NcIcon {
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Bookmark = 'bookmark',
  Calendar = 'calendar',
  Chart = 'chart',
  CheckSmall = 'check-small',
  Check = 'check',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  CircleCheckColored = 'circle-check-colored',
  CircleCheckInverted = 'circle-check-inverted',
  CircleCheck = 'circle-check',
  CircleCrossColored = 'circle-cross-colored',
  CircleCrossInverted = 'circle-cross-inverted',
  CircleCross = 'circle-cross',
  CircleInfoInverted = 'circle-info-inverted',
  CircleInfo = 'circle-info',
  CircleInfoColored = 'circle-info-colored',
  CircleMinusColored = 'circle-minus-colored',
  CircleMinusInverted = 'circle-minus-inverted',
  CirclePause = 'circle-pause',
  CircleMinus = 'circle-minus',
  CircleQuestionInverted = 'circle-question-inverted',
  CircleQuestion = 'circle-question',
  CircleWarningInverted = 'circle-warning-inverted',
  CircleWarning = 'circle-warning',
  Clock = 'clock',
  Copy = 'copy',
  CrossSmall = 'cross-small',
  Cross = 'cross',
  Delete = 'delete',
  Download = 'download',
  Edit1 = 'edit-1',
  Edit2 = 'edit-2',
  Edit4 = 'edit-4',
  File = 'file',
  FileUpload = 'file-upload',
  Filter = 'filter',
  First = 'first',
  GoTo = 'goTo',
  Grip = 'grip',
  Home = 'home',
  Image = 'image',
  InfoSmall = 'info-small',
  Info = 'info',
  Last = 'last',
  Link = 'link',
  Lock = 'lock',
  LockOff = 'lock-off',
  Login = 'login',
  Logout1 = 'logout-1',
  Logout2 = 'logout-2',
  MinusSmall = 'minus-small',
  Minus = 'minus',
  Moon = 'moon',
  Notes = 'notes',
  Patient = 'patient',
  Pause = 'pause',
  Phone = 'phone',
  PinpaperCheck = 'pinpaper-check',
  PinpaperFilled = 'pinpaper-filled',
  PlusCircleInverted = 'plus-circle-inverted',
  PlusCircle = 'plus-circle',
  PlusSmall = 'plus-small',
  Plus = 'plus',
  Print = 'print',
  QrCode = 'qr-code',
  QuestionSmall = 'question-small',
  Question = 'question',
  Refresh = 'refresh',
  Save = 'save',
  ScreenTms = 'screen-tms',
  Search = 'search',
  Send2 = 'send-2',
  Settings = 'settings',
  Share1 = 'share-1',
  Share2 = 'share-2',
  Sleep = 'sleep',
  SleepActigraphy = 'sleep-actigraphy',
  SleepDiary1 = 'sleep-diary-1',
  SleepDiary2 = 'sleep-diary-2',
  Suitcase = 'suitcase',
  Template = 'template',
  Timer = 'timer',
  Trash1 = 'trash-1',
  Trash2 = 'trash-2',
  TriangleDown = 'triangle-down',
  TriangleLeft = 'triangle-left',
  TriangleRight = 'triangle-right',
  TriangleUp = 'triangle-up',
  TriangleWarningColored = 'triangle-warning-colored',
  TriangleWarningInverted = 'triangle-warning-inverted',
  TriangleWarning = 'triangle-warning',
  Upload = 'upload',
  User = 'user',
  UserCheck = 'user-check',
  UserCross = 'user-cross',
  UserPlus = 'user-plus',
  UsersMore = 'users-more',
  VerticalEllipsis = 'vertical-ellipsis',
  Vertical = 'vertical',
  Video = 'video',
  ViewHorizontal = 'view-horizontal',
  ViewVertical = 'view-vertical',
  WarningSmall = 'warning-small',
  Warning = 'warning',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
}
