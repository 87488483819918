import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { LogProperties } from '../interfaces/log-properties';
import { ILoggingService } from './i-logging.service';

export const INSTRUMENTATION_KEY = new InjectionToken<string>(
  'INSTRUMENTATION_KEY',
);

@Injectable({
  providedIn: 'root',
})
export class LoggingService extends ILoggingService {
  appInsights: ApplicationInsights;
  constructor(@Inject(INSTRUMENTATION_KEY) instrumentationKey: string) {
    super();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: false,
      },
    });
    this.appInsights.loadAppInsights();
  }

  override logPageView(name?: string, url?: string): void {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  override logEvent(name: string, properties?: LogProperties): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  override logMetric(
    name: string,
    average: number,
    properties?: LogProperties,
  ): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  override logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  override logTrace(message: string, properties?: LogProperties): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
